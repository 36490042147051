<template>
  <footer class="footer">
    <div class="footer-container">
      
      <div class="footer-logo">
        <div class="footer-container-img">
          <div @click="changeHeader" class="icon-form"></div>
          <img :src="require('../assets/images/betshawty-logo.png')" alt="footer-logo">
        </div>
      </div>
      <div class="footer-list">
        <ul>
            <li v-for="(slide, index)  in routesColumn1" :key="index">
              <a :href="slide.route">{{ slide.name }}</a>
            </li>
        </ul>
      </div>
    </div>
  </footer>
</template>


<script>
/*   import AgentButton from "@/components/agent-button.vue"
  import MobileMenu from '@/components/mobile-menu.vue' */
  import { useI18n } from 'vue-i18n';
  import { reactive, watch } from 'vue';
  
  export default {
    name: "FooterComponent",
    components: {
/*       AgentButton,
      MobileMenu */
    },
    methods:{

    },
    setup(props, {emit}) {

      const { t , locale }  = useI18n();

      const changeHeader = () => {
        emit('changeHeader');
      }
      const routesColumn1= reactive( [
          {
            name       :   t("footerColum1.0.name"),
            route      :   t("footerColum1.0.route")
          },
          {
            name       :   t("footerColum1.1.name"),
            route      :   t("footerColum1.1.route")
          }
        ])

             // Observar los cambios de idioma y actualiza los slides
      const updateSlides = () => {
        routesColumn1.forEach((opcion, index) => {
          opcion.name = t(`footerColum1.${index}.name`);
          opcion.route = t(`footerColum1.${index}.route`);
        });
      };

      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });
      return {
        routesColumn1,
        changeHeader
      }
      
    },
  };
</script>
