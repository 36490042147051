<template>
    <header-component :loginForm = "loginForm"/>
    <router-view/>
    <footer-component @changeHeader = "changeHeader"/>
  </template>
  
  <script>
  import headerComponent from "@/components/header.vue"
  import FooterComponent from "@/components/footer.vue"
  import { ref } from 'vue';
  // import MobileMenu from './components/mobile-menu.vue'
  
  export default {
    name: 'FatherComponent',
    components: {
      headerComponent,
      FooterComponent
      // MobileMenu,
    },

    setup(){
        const loginForm = ref(false);
        const changeHeader = () =>{
            loginForm.value = !loginForm.value;
            console.log("soy ", loginForm.value);
        }

        return{
            loginForm,
            changeHeader
        }
    }
  }
  </script>