import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/home.vue'),
  },
  {
    path: '/reset-pass',
    name: 'reset-pass-langen',
    component: () => import('../pages/reset-pass.vue'),  
  },
  {
    path: '/restablecer-contrasena',
    name: 'reset-pass-langes',
    component: () => import('../pages/reset-pass.vue'),  
  },
  {
    path: '/house-rules',
    name: 'house-rules-langen',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/reglas-de-la-casa',
    name: 'house-rules-langes',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/house-rules-pt',
    name: 'house-rules-langpt',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/sports-rules',
    name: 'sports-rules-langen',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/sports-rules-pt',
    name: 'sports-rules-langpt',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/reglas-deportivas',
    name: 'sports-rules-langes',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/same-game-parlay-rules',
    name: 'same-game-parlay-rules-langen',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
  {
    path: '/reglas-de-parlay-del-mismo-equipo',
    name: 'same-game-parlay-rules-langes',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
  {
    path: '/same-game-parlay-rules',
    name: 'same-game-parlay-rules-langpt',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router