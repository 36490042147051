<template>
  <FatherComponent/>
</template>

<script>
import FatherComponent from './components/father.vue'
// import headerComponent from "@/components/header.vue"
// import FooterComponent from "@/components/footer.vue"
// import MobileMenu from './components/mobile-menu.vue'

export default {
  name: 'App',
  components: {
/*     headerComponent,
    FooterComponent */
    FatherComponent
    // MobileMenu,
  }
}
</script>
