<template>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <router-link class="header-content__container-logo" to="/">
          <picture>
          <source media="(min-width:600px)" srcset="../assets/images/betshawty-logo.png">
          <img class="header-content__img" src="../assets/images/betshawty-logo.png" alt="betshawty" />
        </picture>
          
        </router-link>
      </div>
      <div class="header-content__cta" v-if="loginFormTemp == false">
        <div class="header-buttons">
          <form class="form-login form-desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <div class="box-container">
              <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
              <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
              <input type="text" :placeholder="$t('Username')" name="account" id="account">
              <input type="password" :placeholder="$t('Password')" name="password" id="password">
            </div>
            
            <div class="box-dropdown">
                <div class="lag-container">
                  <div class="container-lag">
                        <div class="container-langs" v-if="this.$i18n.locale =='en'">
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <!-- <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div> -->
                        </div>
                        <div class="container-langs" v-if="this.$i18n.locale =='es'">
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                          <!-- <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div> -->
                        </div>
                        <!-- <div class="container-langs" v-if="this.$i18n.locale =='pt'">
                          <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div>
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                        </div> -->
                        
                        <svg class="flecha" xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 10 16" fill="#ff369b">
                        <path d="M1 1L8 8L1 15" stroke="#ff369b" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                        <!-- Resto del contenido de tu componente -->
                  </div>
                </div>
            </div>
            <div class="box-buttons">
                <button class="btn-login secondary-button secondary-button__betslip" type="submit">{{$t('LOGIN')}}</button>
                <!-- <div class="container-bet-lag">
                    <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
                </div> -->
            </div>
          </form>
          <form class="form-login form-mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <div class="box-container">
              <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
              <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/>
              <input type="text" :placeholder="$t('Username')" name="account" id="account">
              <input type="password" :placeholder="$t('Password')" name="password" id="password">
            </div>
            <div class="box-dropdown">
                  <div class="container-lag">
                        <div class="container-langs" v-if="this.$i18n.locale =='en'">
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <!-- <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div> -->
                        </div>
                        <div class="container-langs" v-if="this.$i18n.locale =='es'">
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                          <!-- <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div> -->
                        </div>
                        <!-- <div class="container-langs" v-if="this.$i18n.locale =='pt'">
                          <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div>
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                        </div> -->
                        
                        <svg class="flecha" xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="#ff369b">
                        <path d="M1 1L8 8L1 15" stroke="#ff369b" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                        <!-- Resto del contenido de tu componente -->
                  </div>
            </div>
            <div class="box-buttons">
                <button class="btn-login secondary-button secondary-button__betslip" type="submit">{{$t('LOGIN')}}</button>
                <!-- <div class="container-bet-lag">
                    <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
                </div> -->
            </div>
          </form>
        </div>
        
      </div>
      <div class="header-content__cta" v-if="loginFormTemp == true">
        <div class="header-buttons">
          <form class="form-login form-desktop" name="aspnetForm" method="post" :action="`https://agents.${backendUrl}/AgentSiteV2/processlogin.aspx`" id="aspnetForm">
            <div class="box-container">
              <input type="text" name="Account" id="Account" :placeholder="$t('Agent')">
              <input type="password" name="Password" id="password" :placeholder="$t('Password')"> 
            </div>
            
            <div class="box-dropdown">
                <div class="lag-container">
                  <div class="container-lag">
                        <div class="container-langs" v-if="this.$i18n.locale =='en'">
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <!-- <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div> -->
                        </div>
                        <div class="container-langs" v-if="this.$i18n.locale =='es'">
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                          <!-- <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div> -->
                        </div>
                        <!-- <div class="container-langs" v-if="this.$i18n.locale =='pt'">
                          <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div>
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                        </div> -->
                        
                        <svg class="flecha" xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 10 16" fill="#ff369b">
                        <path d="M1 1L8 8L1 15" stroke="#ff369b" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                        <!-- Resto del contenido de tu componente -->
                  </div>
                </div>
            </div>
            <div class="box-buttons">
                <input class="btn-Agent secondary-button secondary-button__betslip" type="submit" :value="$t('LOGIN')">
                <p id="errorMsg"></p>
                <!-- <div class="container-bet-lag">
                    <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
                </div> -->
            </div>
          </form>
          <form class="form-login form-mobile" name="aspnetForm" method="post" :action="`https://agents.${backendUrl}/AgentSiteV2/processlogin.aspx`" id="aspnetForm">
            <div class="box-container">
              <input type="text" name="Account" id="Account" :placeholder="$t('Agent')">
              <input type="password" name="Password" id="password" :placeholder="$t('Password')"> 
            </div>
            <div class="box-dropdown">
                  <div class="container-lag">
                        <div class="container-langs" v-if="this.$i18n.locale =='en'">
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <!-- <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div> -->
                        </div>
                        <div class="container-langs" v-if="this.$i18n.locale =='es'">
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                          <!-- <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div> -->
                        </div>
                        <!-- <div class="container-langs" v-if="this.$i18n.locale =='pt'">
                          <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div>
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                        </div> -->
                        
                        <svg class="flecha" xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="#ff369b">
                        <path d="M1 1L8 8L1 15" stroke="#ff369b" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                        
                  </div>
            </div>
            <div class="box-buttons">
                <input class="btn-Agent secondary-button secondary-button__betslip" type="submit" :value="$t('LOGIN')">
                <p id="errorMsg"></p>
                
                <!-- <div class="container-bet-lag">
                    <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
                </div> -->
            </div>
          </form>
        </div>
        
      </div>
      <div id="errorM" v-if="urlLastPosition == '?login-error'" class="error"><p><strong>{{ $t('error') }}</strong></p></div>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
  import ModalComponent from '@/components/modal.vue'
  import { toRef } from 'vue';
  // import MenuComponent from '@/components/menu.vue'
  import { useI18n } from 'vue-i18n';
  import { watch } from 'vue';
  import {computed} from 'vue'


  export default {
    name: "HeaderComponent",
    components: {
      ModalComponent,
      // MenuComponent
    },
    watch: {
          $route(newVar){
          let pathName = computed(() =>newVar);
          this.detectLang(pathName.value.name);
        }
      },
    props:{
        loginForm: {type:Boolean,defaul:true}
    },
    methods: {
      
      openModal() {
        this.$refs.modal.openModal();
      },
      changeLanguage(locale) {
        if(this.$i18n.locale != locale){
          this.$i18n.locale = locale;
          const t = this.$t;
          const currentRoute = this.$router.currentRoute.value;

          if (currentRoute.path !== '/' && currentRoute.path !== '/apk') {
            const translatedPath = `/${t(`routes.${currentRoute.name}`)}`;
            this.$router.push(translatedPath);
          }
        }
        
      },
      getTranslatedRoutes(t) {
        
          const routes = this.$router.options.routes.map((route) => ({
            ...route,
            path: route.path !== '/' ? `/${t(`routes.${route.name}`)}` : '/',
          }));

          return routes;
        },
        detectLang(pathName){
          if(pathName.includes("langes")){
            this.$i18n.locale = "es";
            this.changeLanguage("es")
          }else if(pathName.includes("langen")){
            this.$i18n.locale = "en";
            this.changeLanguage("en")
          }else if(pathName.includes("langpt")){
            this.$i18n.locale = "pt";
            this.changeLanguage("pt")
          }else{
            console.log("default")
          }
      }
    },
    setup(props) {
      const DGS_SITEID = 1084;
      const backendUrl = "betshawty.com";
      const loginFormTemp = toRef(props,'loginForm');
      const { locale }  = useI18n();
      const actualUrl = window.location;
      const urlSlides = actualUrl.href.split('/');
      const urlLastPosition = urlSlides[urlSlides.length-1];
      console.log("HOla",loginFormTemp);


      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        // updateSlides();
      });
      return {
        DGS_SITEID,
        backendUrl,
        loginFormTemp,
        urlImage: require('../assets/images/logoblanco.png'),
        altImage: 'betshawty.com',  
        urlLastPosition,      
      }
    },
  };
</script>
